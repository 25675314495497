<template>
  <div class="add-course-by-plan">
    <div class="filter">
      <div class="left">
        <el-dropdown @command="handleOrder">
          <span class="el-dropdown-link">
            按发布时间排序<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="asc">从近到远</el-dropdown-item>
            <el-dropdown-item command="desc">从远到近</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <div class="search">
          <el-input
            v-model="keyword"
            placeholder="请输入培养方案名称"
            clearable
            type="text"
          ></el-input>
          <div class="btn" @click="search()">
            <i class="el-icon-search"></i>
          </div>
        </div>
      </div>
      <el-button type="primary" :loading="loading" @click="submit">确定添加课程</el-button>
    </div>
    <div class="content">
      <template v-if="planList.length">
        <div class="clearfix">
          <el-checkbox-group v-model="checkList">
            <el-checkbox
              v-for="item in planList"
              :key="item.trainPlanId"
              class="course-item"
              :label="item.trainPlanId"
            >
              <div class="cover">
                <img :src="item.pic" alt="" />
              </div>
              <div class="text" @click="toDetail(item.trainPlanId)">
                <div class="name">{{ item.trainName }}</div>
                <div class="info">适用岗位：{{ item.post }}</div>
                <div class="time">发布时间：{{ item.publishTime }}</div>
              </div>
            </el-checkbox>
          </el-checkbox-group>
        </div>
        <el-pagination
          background
          :current-page="pager.current"
          :page-size="pager.size"
          layout="total, prev, pager, next, jumper"
          :total="pager.total"
          @current-change="handleCurrentChange"
        >
        </el-pagination
      ></template>
      <div v-else class="empty">暂无数据</div>
    </div>
  </div>
</template>
<script>
import { coursePlanList, classCourseAddPlan } from '@/api/class'
import to from 'await-to'
export default {
  name: 'AddCourseByPlan',
  data() {
    return {
      loading: false,
      planList: [],
      keyword: '',
      pager: {
        total: 0,
        size: 16,
        current: 1,
      },
      checkList: [],
    }
  },
  mounted() {
    this.getPlanList()
  },
  methods: {
    async getPlanList(params = {}) {
      const { current, size } = this.pager
      const [res, err] = await to(coursePlanList({ current, size, ...params }))
      if (err) return this.$message.warning(err.msg)
      this.planList = res.data.list
      this.pager.total = res.data.total
    },
    async search(orderByPublishTime) {
      var params = { keyword: this.keyword, orderByPublishTime }
      const { size } = this.pager
      const [res, err] = await to(coursePlanList({ current: 1, size, ...params }))
      if (err) return this.$message.warning(err.msg)
      this.planList = res.data.list
      this.pager.total = res.data.total
    },
    toDetail(trainPlanId) {
      // console.log(trainPlanId)
      this.$router.push(`/class/addCourse/plan/${trainPlanId}`)
    },
    async submit() {
      if (!this.checkList.length) {
        return this.$message.warning('请选择课程')
      }
      const { classId } = this.$route.params
      this.loading = true
      const [, err] = await to(classCourseAddPlan({ classId, trainPlanIdList: this.checkList }))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.$message.success('添加成功')
      this.$router.push(`/class/addCourse/${classId}`)
    },
    handleOrder(command) {
      console.log(command)
      this.search(command)
    },
    // 分页
    handleCurrentChange(current) {
      this.pager.current = current
      this.getPlanList()
    },
  },
}
</script>
<style lang="scss" scoped>
.add-course-by-plan {
  width: 1556px;
  .filter {
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    .left {
      display: flex;
      align-items: center;
    }
    .search {
      margin-left: 40px;
      position: relative;
      width: 240px;
      height: 30px;
      background: rgba(0, 0, 0, 0);
      border: 1px solid #ff7b33;
      border-radius: 16px;
      ::v-deep input {
        box-sizing: border-box;
        width: 240px;
        height: 30px;
        border-radius: 16px;
        border: 0;
        background-color: #f5f5f5;
        outline: none;
        padding: 0 60px 0 10px;
      }
      .btn {
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 0;
        width: 50px;
        height: 30px;
        background: #ff7b33;
        border-radius: 15px;
        text-align: center;
        line-height: 30px;
        i {
          font-size: 18px;
          color: #fff;
        }
      }
    }
  }
  .content {
    box-sizing: border-box;
    padding: 24px 0 0 24px;
    background: #ffffff;
    > div {
      box-sizing: border-box;
      display: flex;
      flex-wrap: wrap;
    }
    ::v-deep.course-item {
      position: relative;
      cursor: pointer;
      box-sizing: border-box;
      overflow: hidden;
      width: 282px;
      border-radius: 4px;
      box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.06);
      margin-right: 23px;
      margin-bottom: 24px;
      transition: transform 0.3s;
      &:hover {
        transform: translateY(-5px);
      }
      .el-checkbox__label {
        padding: 0;
      }
      .el-checkbox__input {
        right: 6px;
        top: 6px;
        position: absolute;
        .el-checkbox__inner {
          box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.13);
        }
      }
      .cover {
        width: 282px;
        height: 145px;
        img {
          @extend %width-height;
        }
      }
      .text {
        box-sizing: border-box;
        width: 282px;
        padding: 14px 10px;
        .name {
          font-size: 14px;
          height: 14px;
          font-weight: 500;
          color: #333333;
          margin-bottom: 7px;
        }
        .info {
          font-size: 12px;
          color: #666666;
          height: 12px;
          margin-bottom: 20px;
        }

        .time {
          font-size: 12px;
          height: 12px;
          text-align: right;
          color: #666666;
        }
      }
    }
    .empty {
      text-align: center;
      font-size: 14px;
      padding-bottom: 20px;
      color: rgba(0, 0, 0, 0.25);
    }
  }
  .el-pagination {
    padding-bottom: 20px;
  }
}
</style>
